import React, { useState, useEffect} from 'react'
import * as S from '../menu/styles'
import { graphql, navigate } from 'gatsby'

import NavbarTotem from '../../../components/totem/navbarTotem'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { arrayChunk } from '../../../helpers/util'

const MenuTotem = ({
  data: {
    allTotemMenu: { edges: totemMenus },
  },
  pageContext: { loja },
}) => {


  useEffect(() => {
    const isOfertas = !!localStorage.getItem("concluiu-oferta");
    if(isOfertas === true){
      localStorage.removeItem("concluiu-oferta");
      window.location.reload()
    }
  }, []);

  const OfertaClick= (teste) => {
    const items = !!localStorage.getItem("Ofertas");
    if(items == true){
      localStorage.removeItem("Ofertas", teste);
      localStorage.setItem("Ofertas", teste);
    } else{
      localStorage.setItem("Ofertas", teste);
    }
  };

  return (
    <S.MenuTotem>
      <NavbarTotem loja={loja}  />
      <div className="title">
        <h1>
          COMPARE PRODUTOS, TESTE SEU TÊNIS IDEAL, PEGUE DESCONTOS E MAIS!
        </h1>
      </div>
      {/*TODO verificar se é link externo e tratar de acordo*/}
      {arrayChunk(totemMenus, 2).map((chunk, k) => (
        <S.MenuRow key={`menu-${k}`}>
          {chunk.map(({ node: menu }) => (
            menu.url.startsWith('http')?
            <S.NavLinkExt
              href={menu.url}
              key={menu.id}
              id={`btn-${menu.url}`}
              target={'_blank'}
              onClick={(e) => OfertaClick(menu.oferta, e)}
            >
              <div className='box-img-menu'>
                <GatsbyImage
                  alt={menu.titulo}
                  image={getImage(menu.localImagem)}
                />
              </div>

              <p>{menu.titulo}</p>
            </S.NavLinkExt>
            :
            <S.NavLink
              to={`/totem/${loja.slug}/${menu.url}`}
              key={menu.id}
              id={`btn-${menu.url}`}
              onClick={(e) => OfertaClick(menu.oferta, e)}
            >
              <div className='box-img-menu'>
                <GatsbyImage
                  alt={menu.titulo}
                  image={getImage(menu.localImagem)}
                />
              </div>

              <p>{menu.titulo}</p>
            </S.NavLink>
          ))}
        </S.MenuRow>
      ))}
    </S.MenuTotem>
  )
}

MenuTotem.propTypes = {
  data: PropTypes.shape({
    allTotemMenu: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string.isRequired,
            evento_dito: PropTypes.string.isRequired,
            oferta: PropTypes.string.isRequired,
            titulo: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            localImagem: PropTypes.object.isRequired,
          }),
        })
      ),
    }),
  }),
  pageContext: PropTypes.shape({
    loja: PropTypes.shape({
      nome: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
    slugLoja: PropTypes.string.isRequired,
  }).isRequired,
}

export default MenuTotem

export const query = graphql`
  query ($language: String!, $slugLoja: [String]) {
    allTotemMenu(
      filter: { alternative_id: { ne: 0 }, lojas: { in: $slugLoja } }
      sort: { order: ASC, fields: ordem }
    ) {
      edges {
        node {
          id
          evento_dito
          oferta
          titulo
          url
          localImagem {
            name
            childImageSharp {
              gatsbyImageData(
                width: 100
                height: 100
                transformOptions: { fit: INSIDE }
                placeholder: BLURRED
                quality: 70
              )
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
