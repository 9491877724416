import styled from 'styled-components'

import { Link } from 'gatsby-plugin-react-i18next'

export const MenuTotem = styled.div`

  background-color: #c4ddf2;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 102vh;
  padding-bottom: 30px;
  margin: -8px;
  padding-right: 16px;
  background-position: center;
  background-size: cover;

  .title {
    padding: 150px 60px 30px 60px;
    text-align: center;
    h1 {
      color: #001e62;
      font-style: italic;
      font-family: 'ASICSFont3.0-Bold', sans-serif;
      font-weight: 700;
      font-size: 30px;
      line-height: 32px;
    }
    @media (max-width: 767px) {
      padding: 130px 50px 20px;
      h1{
        font-size: 26px;
      }
    }
    @media (max-width: 450px){
      padding: 80px 15px 0px;
      h1{
        font-size: 18px;
        line-height: 20px;
      } 
    }
  }
`

export const MenuRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 35px;
  padding-bottom: 35px;

  .box-img-menu{
    height: 90px;
  }

  @media (max-width: 767px){
    gap: 40px;
    padding-bottom: 30px;
    padding-right: 55px;
    padding-left: 55px;
  }
  @media (max-width: 450px){
    gap: 16px;
    padding: 8px 0px 8px 15px;
  }
`

export const NavLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 16.1275px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 20px;
  min-height: 180px;
  justify-content: center;
  text-decoration: none;
  width: 237.074px;
  height: 237.074px;
  transition: background 0.4s;

  @media (max-width: 767px){
    min-height: 190px;
    padding: 15px 15px;
  }
  @media (max-width: 450px){
    width: 120px;
    height: 130px;
    min-height: 140px;
  }

  p {
    font-family: 'ASICSFont3.0-BoldItalic';
    font-style: italic;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #001e62;
    padding-top: 25px;
    margin: 0;
    transition: color 0.4s;
    @media (max-width: 767px){
      font-size: 18px;
      line-height: 20px;
    }
    @media (max-width: 450px){
      font-size: 14px;
      line-height: 16px;
      padding-top: 15px;
    }
  }
  img {
    transition: filter 0.4s;
  }

  &:hover {
    background: #4a5fc4;
    img {
      filter: contrast(100%) brightness(125%);
    }
    p {
      color: #ffffff;
    }
  }
`
export const NavLinkExt = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 16.1275px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 20px;
  min-height: 180px;
  justify-content: center;
  text-decoration: none;
  width: 237.074px;
  height: 237.074px;
  transition: background 0.4s;

  @media (max-width: 767px){
    min-height: 190px;
    padding: 15px 15px;
  }
  @media (max-width: 450px){
    width: 120px;
    height: 130px;
    min-height: 140px;
  }

  p {
    font-family: 'ASICSFont3.0-BoldItalic';
    font-style: italic;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #001e62;
    padding-top: 25px;
    margin: 0;
    transition: color 0.4s;
    @media (max-width: 767px){
      font-size: 18px;
      line-height: 20px;
    }
    @media (max-width: 450px){
      font-size: 14px;
      line-height: 16px;
      padding-top: 15px;
    }
  }
  img {
    transition: filter 0.4s;
  }

  &:hover {
    background: #4a5fc4;
    img {
      filter: contrast(100%) brightness(125%);
    }
    p {
      color: #ffffff;
    }
  }
`
